import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/ganja-and-hess-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1973 vampire art film, Ganja and Hess"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 34</h1>
                    <h2>Ganja and Hess</h2>
                    <h3>February 12, 2024</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Bill Gunn // <b>Starring:</b> Duane Jones &amp; Marlene Clark</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/34-Ganja--Hess-e2flr7n/a-aav2rog" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe episode 34, Ganja and Hess"></iframe>
                        </div>
                        <p><Link to="/transcripts/ganja-and-hess">Read the episode transcript</Link></p>
                        <p>This week, Dave and Bryan celebrate Black History Month with a look at Bill Gunn's delirious 1973 vampire movie, Ganja & Hess, which stars Gunn, Marlene Clark, and Duane Jones from Night of the Living Dead. It's a sleepy, dreamy art film about black identity, gender, class, and the loneliness of a man without ancestry as damaged people deal with themes of blackness in America through the gauzy lens of a vampire movie. Often lumped in with blaxploitation movies like Blacula and Shaft, it is anything but.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/my-bloody-valentine">Next Episode</Link></li>
                        <li><Link to="/episodes/x-ray">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)